<template>
    <div id="edicionMenuTop" class="edicion_menu ">
         <nav class="edicion_top_nav" style="padding-top:8px;">
             <div class="d-flex justify-content-between">
                   <ul v-if="editor" class="etn_menu" >
                    <li @click="$bus.$emit('toggleMobileEditor',false)" :style="showDesignButtons ? 'width:100% !important;text-align:center;' : 'width:100% !important;text-align:left;'">
                           <span style="margin-right:2px;text-align:right;">{{$t('filtros.ocultar')}}</span> <font-awesome-icon :icon="['fas','chevron-down']" />
                       </li>
                       <template v-if="showDesignButtons">
                           <li class="manito" :class="{ active: currentCompDesign === 'leftColDC' }" style="width:100% !important;text-align:center;float:right;border-right: solid 1px #ddd;" @click="switchComponentRightDesign('leftColDC')">
                               <span style="text-align:center;">{{$t('bc.blocks')}}</span>
                           </li>
                           <li class="manito"  :class="{ active: currentCompDesign === 'leftColDM' }" style="width:100% !important;text-align:center;float:right;border-right: solid 1px #ddd;" @click="switchComponentRightDesign('leftColDM')">
                               <span style="text-align:center;">{{$t('bc.mydesigns')}}</span>
                           </li>
                           <li class="manito" :class="{ active: currentCompDesign === 'leftColDT' }" style="width:100% !important;text-align:center;float:right;" @click="switchComponentRightDesign('leftColDT')">
                               <span style="text-align:center;">{{$t('bc.templates')}}</span>
                           </li>
                       </template>
                   </ul>
                   <ul v-else class="etn_menu ">
                       <li @click="$bus.$emit('toggleMobileEditor',true)" :style="showDesignButtons ? 'width:100% !important;text-align:center;' : 'width:100% !important;text-align:left;'">
                        <span style="margin-right:2px;text-align:right;">{{$t('filtros.mostrar')}}</span> <font-awesome-icon :icon="['fas','chevron-up']" />
                       </li>
                       <template v-if="showDesignButtons">
                           <li class="manito" :class="{ active: currentCompDesign === 'leftColDC' }" style="width:100% !important;text-align:center;float:right;border-right: solid 1px #ddd;" @click="switchComponentRightDesign('leftColDC')">
                               <span style="text-align:center;">{{$t('bc.blocks')}}</span>
                           </li>
                           <li class="manito"  :class="{ active: currentCompDesign === 'leftColDM' }" style="width:100% !important;text-align:center;float:right;border-right: solid 1px #ddd;" @click="switchComponentRightDesign('leftColDM')">
                               <span style="text-align:center;">{{$t('bc.mydesigns')}}</span>
                           </li>
                           <li class="manito" :class="{ active: currentCompDesign === 'leftColDT' }" style="width:100% !important;text-align:center;float:right;" @click="switchComponentRightDesign('leftColDT')">
                               <span style="text-align:center;">{{$t('bc.templates')}}</span>
                           </li>
                       </template>
                   </ul>
             </div>
         </nav>
   </div>
   </template>
   
   <script>
   
   export default {
       data() {
           return {
               editor:true,
               currentCompDesign: 'leftColDC',
               showDesignButtons: true
           }
       },
       created(){
           console.log('$store.state.sobreComp', this.$store.state.sobreComp)
           
           this.showDesignButtons = this.$store.state.sobreComp !== 'leftColSettings';
   
           this.$bus.$on('sobreCompChanged', (value) => {
               console.log('sobreCompChanged event received:', value);
               this.showDesignButtons = value !== 'leftColSettings';
           });
   
           this.$bus.$on('toggleMobileEditor', state => {
               this.editor=state
           });
           this.$bus.$on('currentCompSobreEditables', element => {
               this.editor = true;
               this.$bus.$emit('toggleMobileEditor', true);
           });
   
       },
       watch: {
           '$store.state.sobreComp': {
               handler(newValue) {
                   console.log('$store.state.sobreComp changed to:', newValue);
                   this.showDesignButtons = newValue !== 'leftColSettings';
               },
               deep: true,
               immediate: true
           }
       },
       methods:{
           switchComponentRightDesign(comp) {
               this.currentCompDesign=comp
               this.$bus.$emit('switchComponentRightDesign', comp)
           },
       },    
   }
   
   </script>
   
   
   <style scoped>
   .edicion_top_nav{
       padding-top:0px !important;
   }
   .edicion_top_nav .etn_menu > li{
       border:0px;
   }
   .etn_menu .active .etn_hide,
   .etn_menu .active a .svg{
       color: white !important;
   }
   
   .etn_menu li.active,
     .etn_menu li:active,
     .etn_menu li.pressed {
       background: #686868 !important;
       color: #ededed !important;
     }
     
     .etn_menu li.active span,
     .etn_menu li:active span,
     .etn_menu li.pressed span {
       color: #ededed !important;
     }
   </style>